var CATEGORIES = (function () {
    var ALL_CATEGORIES = [
        {type: "department", id: "1", name: "department", label: "DEPARTMENT"},
        {type: "department", id: "277", name: "check-in-out", label: "CHECK_IN/OUT"},
        {type: "department", id: "294", name: "clinic", label: "CLINICS"},
        //{type: "department", id: "51", name: "clinic-department", label: "CLINICAL_DEPARTMENTS"},
        {type: "department", id: "72", name: "imaging-diagnostic", label: "IMAGING_DIAGNOSTIC"},
        {type: "general", id: "196", name: "radiology", label: "RADIOLOGY"},
        {type: "general", id: "242", name: "hospital-department", label: "HOSPITAL_DEPARTMENT"},
        {type: "points-of-interest", id: "346", name: "banks-atm", label: "BANKS_ATM"},
        {type: "points-of-interest", id: "101", name: "chapel", label: "CHAPEL"},
        {type: "points-of-interest", id: "12", name: "pharmacy", label: "PHARMACY"},
        {type: "points-of-interest", id: "13", name: "conference-rooms", label: "CONFERENCE_ROOMS"},
        {type: "points-of-interest", id: "15", name: "elevator", label: "ELEVATOR"},
        {type: "points-of-interest", id: "255", name: "gift-shop", label: "GIFT_SHOP"},
        {type: "points-of-interest", id: "300", name: "volunteer-services", label: "VOLUNTEER_SERVICES"},
        {type: "points-of-interest", id: "199", name: "services", label: "SERVICES"},
        {type: "points-of-interest", id: "20", name: "parking-pay-station", label: "PARKING_PAY_STATIONS"},
        {type: "points-of-interest", id: "179", name: "classroom", label: "CLASSROOM"},
        {type: "points-of-interest", id: "112", name: "auditorium", label: "AUDITORIUM"},
        {type: "general", id: "264", name: "emergency", label: "EMERGENCY"},
        {type: "general", id: "29", name: "information-desk", label: "INFORMATION_DESK"},
        {type: "general", id: "7", name: "parking", label: "PARKING"},
        {type: "general", id: "9", name: "restroom", label: "RESTROOM"},
        {type: "general", id: "296", name: "patient-rooms", label: "PATIENT_ROOMS"},
        {type: "entrance", id: "4", name: "entrance", label: "ENTRANCE_EXIT"},
        {type: "entrance", id: "267", name: "access-walkway", label: "ACCESS_WALKWAY"},
        {type: "waiting-area", id: "257", name: "waiting-area", label: "WAITING_AREA"},
        {type: "waiting-area", id: "81", name: "lobby", label: "LOBBY"},
        {type: "waiting-area", id: "161", name: "lounge", label: "LOUNGE"},
        {type: "dining", id: "16", name: "dining", label: "DINING"},
        {type: "general", id: "325", name: "rehabilitation-centers", label: "REHABILITATION_CENTERS"},
        {type: "general", id: "281", name: "medical-records", label: "MEDICAL_RECORDS"},
        {type: "general", id: "178", name: "procedures-and-testing", label: "PROCEDURES_AND_TESTING"},
        {type: "general", id: "270", name: "administration", label: "ADMINISTRATION"},
        {type: "general", id: "194", name: "admissions", label: "ADMISSIONS"},
        {type: "general", id: "299", name: "inpatient-care-units", label: "INPATIENT_CARE_UNITS"},
        {type: "general", id: "77", name: "security", label: "SECURITY"},
        {type: "general", id: "243", name: "laboratory", label: "LABORATORY"},
        {type: "general", id: "182", name: "health-center-clinics", label: "HEALTH_CENTER_CLINICS"},
        {type: "general", id: "291", name: "hearth-vascular-institute", label: "HEARTH_VASCULAR_INSTITUTE"},
        {type: "general", id: "305", name: "medical-services", label: "MEDICAL_SERVICES"},
        {type: "general", id: "5", name: "patient-services", label: "PATIENT_SERVICES"},
        {type: "general", id: "150", name: "medical-offices", label: "MEDICAL_OFFICES"},
        {type: "dining", id: "21", name: "vending-machines", label: "VENDING_MACHINES"},
        {type: "general", id: "268", name: "outpatient-services", label: "OUTPATIENT_SERVICES"}
    ];

    var OUTDOOR_CATEGORIES = [
        
    ];

    return {
        getAllCategoriesArray: function () {
            return ALL_CATEGORIES;
        },

        getOutdoorCategoriesArray: function () {
            return OUTDOOR_CATEGORIES;
        },

        getParentCategories: function() {
            var parentCategories = [];
            this.getAllCategoriesArray().forEach(function (item) {
                if (!parentCategories.includes(item.type) && item.type !== 'general') parentCategories.push(item.type);
            });
            return parentCategories;
        },

        getAllCategoriesClasses: function () {
            var categoriesNames = this.getAllCategoriesArray().map(function (category) {
                return category.name
            });
            return categoriesNames.concat(this.getParentCategories()).join(' ');
        }
    };
})();