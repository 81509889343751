var ComplexRoute = function(parameters)
 {
 	var _this = this;
 	var modeTransportation = (parameters.modeTransportation)?parameters.modeTransportation:'driving';
 	var origin = (parameters.start)?parameters.start:{};
 	var destination = (parameters.end)?parameters.end:{};
 	var direction = (parameters.direction)?parameters.direction:'outdoor-indoor';
 	var hasShuttle = (parameters.hasShuttle)?parameters.hasShuttle:false;
 	var legs = (parameters.legs)?parameters.legs:[];
 	var mergeFloorChangeInstructions = (parameters.mergeFloorChangeInstructions)?parameters.mergeFloorChangeInstructions:false;
 	var directionsUseDistance = parameters.directionsUseDistance;
 	var enableLandmarks = parameters.enableLandmarks;
 	var translator = parameters.translator;
 	var vgIds = parameters.vgIds;
 	var floors = parameters.floors;
 	var buildings = parameters.buildings;
 	var mapviewer = parameters.mapviewer;
 	var googleController = parameters.googleController;
 	var totalStages =0;
 	var totalInstructions = 0;
 	var stages = [];
 	var legIndex = 0;
 	var deferredRoute;
	var myRoute;

	errorMessages = {
		'SAME_START_END': translator.getLangLabel('SAME_START_END'),
		'ERROR_ROUTE': translator.getLangLabel('ERROR_ROUTE'),
		'ERROR_INDOOR_ROUTE': translator.getLangLabel('ERROR_INDOOR_ROUTE'),
		'ERROR_OUTDOOR_ROUTE': translator.getLangLabel('ERROR_OUTDOOR_ROUTE'),
		'ERROR_ZERO_RESULTS': translator.getLangLabel('ERROR_ZERO_RESULTS')
	};

 	var computeLeg = function(handicap){
 		handicap = (typeof(handicap) === 'undefined') ? false : ((typeof(handicap) === 'boolean') ? handicap : false);
 		if(legIndex < legs.length){
 			var leg = legs[legIndex];
	 		if(leg.type === 'indoor'){
	 			computeIndoorRoute(leg, handicap);
	 		}else if(leg.type === 'shuttle'){
	 			computeShuttleRoute(leg);
	 		}else if(leg.type === 'outdoor'){
	 			computeOutdoorRoute(leg);
	 		}
 		}else{
 			//console.log('total Stages: '+totalStages);
 			//console.log('total Instructions: '+totalInstructions);
 			deferredRoute.resolve(stages);
 		}
 	}

 	//var computeIndoorRoute = function(pLeg){
 	var computeIndoorRoute = function(pLeg, handicap){
 		handicap = (typeof(handicap) === 'undefined') ? false : ((typeof(handicap) === 'boolean') ? handicap : false);

 		var route_params = {};
		route_params.dst = pLeg.end.map_id; 
		route_params.src = pLeg.start.map_id;
		route_params.computeNavigation = true;
		route_params.navigationParameters= {
		   "firstNodeAsIntersection": false,
		   "mergeFloorChangeInstructions": false
		}

		if (handicap === true) {
			route_params.routingParameters = {};
			route_params.routingParameters.excludedAttributes = [
				'stairway', 'escalator'
			];
		}

    mapviewer.computeRoute(route_params).then(function(data) {
			if (data.data.status && data.data.status != 200) {
				deferredRoute.reject({message: errorMessages["ERROR_INDOOR_ROUTE"]});
				console.error("computeIndoorRoute() can not calculate indoor route");
				return;
			}
			myRoute = new MyRoute(mapviewer, data.data);

			if (myRoute.isValid()) {
				myRoute.hide();
				parseIndoorData(data.data,pLeg);
				myRoute.show();
				legIndex++;
				computeLeg(handicap);
			} else {
				console.error("Problems rendering the route.");
				deferredRoute.reject({message: errorMessages["ERROR_INDOOR_ROUTE"]});
			}	    
		}).catch(function(err) {
			deferredRoute.reject({message: errorMessages["ERROR_INDOOR_ROUTE"]});
			console.error("Sorry, can't find a route to destination. " +route_params.dst );
		});
 	}

 	var computeShuttleRoute = function(pLeg){
 		var shuttleRoute = googleController.getShuttleMetroRoute();
		//console.log(shuttleRoute);
		var directionRoute = shuttleRoute.stages[pLeg.direction];
		var shuttleTotalStages = directionRoute.length;
		for(var j=0;j < shuttleTotalStages; j++){
			var stage = directionRoute[j];
			var instructions = stage['instructions'];
			var tempInst = [];
			for(var i=0;i<instructions.length;i++){
				var inst = {};
				inst['global_inst_index'] = totalInstructions;
				inst['stage_inst_index'] = i;
				inst['stage_index'] = totalStages;
				inst['type'] = 'si';
				inst['icon'] = instructions[i]['icon'];
				inst['detail'] = instructions[i]['detail'];
				inst['si_inst_index'] = i;

				inst['inst_start_point'] = shuttleRoute[instructions[i]['start']]['coordinates'][0];
				inst['inst_end_point'] = shuttleRoute[instructions[i]['end']]['coordinates'][0];
				tempInst.push(inst);
				totalInstructions++;
			}
			stages.push({map_type:'outdoor',stage_index:totalStages,floor:'',instructions:tempInst,isShuttle:true,stage_start_point:shuttleRoute[stage['start']]['coordinates'][0],stage_end_point:shuttleRoute[stage['end']]['coordinates'][0],name:stage.name,stage_mode:pLeg.mode,si_stage_index:j,direction:pLeg.direction});
			totalStages++;
		}
		
 		legIndex++;
		computeLeg();
 	}

 	var computeOutdoorRoute = function(pLeg){
    var addShuttle = (pLeg.addShuttle)?pLeg.addShuttle:false;
 		googleController.calculateRoute({lat:pLeg.start.lat,lng:pLeg.start.lng},{lat:pLeg.end.lat,lng:pLeg.end.lng},pLeg.waypoints,pLeg.mode,{start:pLeg.iconStart,end:pLeg.iconEnd},addShuttle).then(function(pRoute){
			parseOutdoorRoute(pRoute,pLeg);
			legIndex++;
			computeLeg();
		}).fail(function(status){//ZERO_RESULTS
			var message = errorMessages["ERROR_OUTDOOR_ROUTE"];
			if(status === 'ZERO_RESULTS'){
				message = errorMessages["ERROR_ZERO_RESULTS"];
			}
			deferredRoute.reject({message: message});
			console.error('Can\'t calculate google map route: '+status);
		});
 	}


 	var parseIndoorData = function(pIndoorRoute,pLeg){
 		//console.log(pIndoorRoute);
 		var instructions = pIndoorRoute['navigation']['instructions'];
		var currentFloor = instructions[0]['dataset'];
		var index=0;
		var stage_inst_index=0;
		var instructionsArray =[];
		var legMetadata = {
			direction:direction,
			modeTransportation:modeTransportation,
			legOrigin:{name:pLeg.start.name,category:pLeg.start.category},
			legDestination:{name:pLeg.end.name,category:pLeg.end.category},
			hasShuttle:hasShuttle
		}
		if (typeof(instructions[0].brief) === 'undefined')
		{
			var ntranslator = new MyNavigationTranslator(pIndoorRoute['navigation'],vgIds,floors,buildings,directionsUseDistance,enableLandmarks);
			ntranslator.translateInstructions(instructions,translator,legMetadata,mergeFloorChangeInstructions);
		}
		instructionsArray.push({map_type:'indoor',stage_index:totalStages,floor:currentFloor,instructions:[],isShuttle:false,stage_start_point:{lat:instructions[0]['position']['lat'],lng:instructions[0]['position']['lon']},stage_end_point:{},name:translator.getLangLabel('INDOOR_ROUTE'),stage_mode:pLeg.mode});
		totalStages++;
		$.each(instructions,function(i,inst){
			var floor = inst['dataset'];
			if(currentFloor!== floor){
				currentFloor = floor;
				index++;
			}
			if(instructionsArray.length <= index){
				//Insert end of previous floor
				var pFloor = instructionsArray[index-1];
				var pInstruction = instructions[i-1];
				pFloor['stage_end_point'] = {lat:pInstruction['position']['lat'],lng:pInstruction['position']['lon']};
				//Insert new floor
				instructionsArray.push({map_type:'indoor',stage_index:totalStages,floor:currentFloor,instructions:[],isShuttle:false,stage_start_point:{},stage_end_point:{},name:translator.getLangLabel('INDOOR_ROUTE'),stage_mode:pLeg.mode});
				totalStages++;
				stage_inst_index=0;
			}
			inst['global_inst_index'] = totalInstructions;
			inst['stage_inst_index'] = stage_inst_index;
			inst['vg_inst_index'] = i;
			inst['stage_index'] = totalStages-1;
			inst['type'] = 'vg';
			inst['inst_start_point'] = {lat:inst['position']['lat'],lng:inst['position']['lon']};
			inst['inst_end_point'] = {lat:inst['position']['lat'],lng:inst['position']['lon']};
			instructionsArray[index]['instructions'].push(inst);
			if(i === instructions.length -1){
				instructionsArray[index]['stage_end_point'] = {lat:inst['position']['lat'],lng:inst['position']['lon']};
			}
			totalInstructions++;
			stage_inst_index++;
		});

		if(instructionsArray.length){
			jQuery.merge(stages, instructionsArray);
		}
 	}

 	var parseOutdoorRoute = function(pOutdoorRoute,pLeg){
 		//console.log(pOutdoorRoute);
 		var ggRouteIndex = pOutdoorRoute.index;
 		var outdoorRoute = pOutdoorRoute.route;
 		var mainGoogleLeg = outdoorRoute.routes[0]['legs'][0];
 		var instructions = mainGoogleLeg['steps'];
		var lengthInstructions = instructions.length;
		var stageTitle = (pLeg.mode === 'transit')?translator.getLangLabel('TRANSIT_ROUTE'):(pLeg.mode === 'walking')?translator.getLangLabel('WALKING_ROUTE'):translator.getLangLabel('STREET_ROUTE');
		var legMetadata = {
			direction:direction,
			modeTransportation:modeTransportation,
			legOrigin:{name:pLeg.start.name,category:pLeg.start.category},
			legDestination:{name:pLeg.end.name,category:pLeg.end.category},
			hasShuttle:hasShuttle
		}
		if(lengthInstructions>0){
			var gtranslator = new GoogleNavigationTranslator();
			gtranslator.translateInstructions(instructions,legMetadata,translator);
			$.each(instructions,function(i,inst){
				inst['global_inst_index'] = totalInstructions;
				inst['stage_inst_index'] = i;
				inst['stage_index'] = totalStages;
				inst['type'] = 'gg';
				inst['inst_start_point'] = {lat:inst.start_point.lat(),lng:inst.start_point.lng()};
				inst['inst_end_point'] = {lat:inst.end_point.lat(),lng:inst.end_point.lng()};
				inst['gg_route_index'] = ggRouteIndex;
				instructions[i] = inst;
				totalInstructions++;
			});
			stages.push({map_type:'outdoor',stage_index:totalStages,floor:'',instructions:instructions,isShuttle:false,stage_start_point:{lat:mainGoogleLeg.start_location.lat(),lng:mainGoogleLeg.start_location.lng()},stage_end_point:{lat:mainGoogleLeg.end_location.lat(),lng:mainGoogleLeg.end_location.lng()},name:stageTitle,stage_mode:pLeg.mode});
			totalStages++;
		}
 	}

 	this.computeRoute = function(handicap){
		handicap = (typeof(handicap) === 'undefined') ? false : ((typeof(handicap) === 'boolean') ? handicap : false);
		deferredRoute = jQuery.Deferred();
		var result = deferredRoute.promise();
		legIndex = 0;
		var originMapId = origin.buildingAddress[0]['map_id'];
		var destMapId = destination.buildingAddress[0]['map_id'];
		if(direction === 'indoor-indoor' && originMapId === destMapId){
			deferredRoute.reject({message: errorMessages["SAME_START_END"]});
		}else if(direction === 'outdoor-outdoor' && origin.id === destination.id){
			deferredRoute.reject({message: errorMessages["SAME_START_END"]});
		}else if(legs.length>0){
			computeLeg(handicap);
		}

		return result;	
	}
  
	this.getMergeFloorChangeInstructions = function(){
		return mergeFloorChangeInstructions;
	}

	this.getTotalStages = function(){
		return totalStages;
	}

	this.getStages = function(){
		return stages;
	}

	this.getTotalInstructions = function(){
		return totalInstructions;
	}

	this.getRouteDirection = function(){
		return direction;
	}

	this.remove = function(){
		if (myRoute != null) {
			myRoute.remove();
		}

		googleController.removeRoute();
		totalStages =0;
 		totalInstructions = 0;
 		stages = [];
 		legIndex = 0;
 		deferredRoute = null;
 		myRoute = null;
	}

	this.getShuttleStage = function(){
		for(var i=0; i<stages.length;i++){
			var stage = stages[i];
			if(stage.isShuttle){
				return stage;
			}
		}
	}

 };

var RoutePoint= function(pObj){
	this.id = (pObj.id)?pObj.id:-1;
	this.name = (pObj.name)?pObj.name:'';
 	this.mapId = (pObj.map_id)?pObj.map_id:'';
  	this.buildingAddress= [];
  	this.acronym=(pObj.acronym)?pObj.acronym:'';
  	this.imageUrl=(pObj.image_url)?pObj.image_url:'';
  	this.type=(pObj.type)?pObj.type:0;
	this.description=(pObj.description)?pObj.description:'';
	this.specialty=(pObj.specialty)?pObj.specialty:'';
	this.organizationId=(pObj.organization_id)?pObj.organization_id:'';
	this.classificationId=(pObj.classification_id)?pObj.classification_id:'';
	this.email=(pObj.email)?pObj.email:'';
	this.position=(pObj.position)?pObj.position:0;
	this.category=(pObj.category)?pObj.category:'';
	this.mapType=(pObj.map_type)?pObj.map_type:'vg';//vg / gg
	this.toAddress=(pObj.toAddress)?pObj.toAddress:0;
	this.phone=(pObj.phone)?pObj.phone:'';
	this.profileImageUrl=(pObj.profileImageUrl)?pObj.profileImageUrl:'';
	this.lat=(pObj.lat)?pObj.lat:0;
	this.lng = (pObj.lng) ? pObj.lng : 0;
	this.pavilion=(pObj.pavilion)?pObj.pavilion:'';

	if(pObj.building_address && pObj.building_address.length>0){
		var addressArray = [];
		for(var i=0; i < pObj.building_address.length; i++){
			var add = pObj.building_address[i];
			addressArray.push({
				branch: add.branch,
				building_address: add.building_address,
				building_id: add.building_id,
				building_name: add.building_name,
				campus: add.campus,
				floor: add.floor,
				floor_number: add.floor_number,
				hours_of_operation: add.hours_of_operation,
				id: add.id,
				lat: add.lat,
				lng: add.lng,
				map_id: add.map_id,
				phone: add.phone,
				prime: add.prime,
				site: add.site,
				vg_building_id:add.vg_building_id,
				vg_floor_id:add.vg_floor_id,
				website:add.website,
				wing_name: add.wing_name,
				gar_map_id:add.gar_map_id,
				ent_map_id:add.ent_map_id,
        		pavilion:add.pavilion,
			});
		}
		this.buildingAddress = addressArray;
	}
};
